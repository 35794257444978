import moment from 'moment';
import { Controller } from '@hotwired/stimulus';
import {
  ElementEnabledMixin,
  useElementEnabled,
} from '../../mixins/useElementEnabled';

const DATE_RANGE_PRESETS = [
  'today',
  'thisWeek',
  'lastWeek',
  'thisMonth',
  'lastMonth',
  'last30Days',
  'last90Days',
  'thisYear',
  'lastYear',
] as const;
type DateRangePreset = typeof DATE_RANGE_PRESETS[number];

// Connects to data-controller="dashboard-date-filter"
export default class extends Controller {
  static values = {
    defaultStartDate: String, // in iso8601 format
    defaultEndDate: String, // in iso8601 format
  };

  static targets = [
    'button',
    'startDateLabel',
    'endDateLabel',

    'modal',
    'startDateInput',
    'endDateInput',

    'compareCheckbox',

    'applyButton',
    'resetButton',

    'presetDateRangeButton',
  ];

  // Types
  declare defaultStartDateValue: string;
  declare defaultEndDateValue: string;
  declare readonly buttonTarget: HTMLButtonElement;
  declare readonly startDateInputTarget: HTMLInputElement;
  declare readonly startDateLabelTarget: HTMLSpanElement;
  declare readonly endDateInputTarget: HTMLInputElement;
  declare readonly endDateLabelTarget: HTMLSpanElement;
  declare readonly modalTarget: HTMLDivElement;
  declare readonly compareCheckboxTarget: HTMLInputElement;
  declare readonly applyButtonTarget: HTMLButtonElement;
  declare readonly resetButtonTarget: HTMLButtonElement;

  declare readonly presetDateRangeButtonTargets: HTMLInputElement[];

  // Custom properties and methods
  private selectedStartDate: moment.Moment = this.getDefaultStartDate();
  private selectedEndDate: moment.Moment = this.getDefaultEndDate();
  private selectedPreset: DateRangePreset | undefined =
    this.getPresetForCurrentDateRange();
  private compare: boolean = false;

  // Mixins
  private elementEnabledMixin: ElementEnabledMixin;

  connect() {
    super.connect();

    // Setup mixins
    this.elementEnabledMixin = useElementEnabled(this);

    this.setupEventListeners();

    this.updateSelectedDateInputs(); // will use the default range initially
    this.updateSelectedDateLabels();
    this.limitEndDateInputAvailableRange();
    this.autoCheckPresetForDateRangeInputsValues();
  }

  private setupEventListeners() {
    const handleDateChange = (e: Event) => {
      const element = e.target as HTMLInputElement;

      // Changed the start date?
      if (element === this.startDateInputTarget) {
        this.limitEndDateInputAvailableRange();
      }

      this.autoCheckPresetForDateRangeInputsValues();
      this.validateAndToggleApplyButton();
    };

    this.startDateInputTarget.addEventListener('change', handleDateChange);
    this.endDateInputTarget.addEventListener('change', handleDateChange);

    // Setup preset date range selection button
    this.presetDateRangeButtonTargets.forEach((target) => {
      target.addEventListener('click', (e: Event) => {
        const element = e.target as HTMLInputElement;
        this.uncheckPreviousDateRangePreset();

        // When unselecting a preset on this date modal opening session, revert
        // to the date it was set previously when the modal was first opened.
        if (!element.checked) {
          this.resetDateInputValues();
          this.autoCheckPresetForDateRangeInputsValues();
          return;
        }

        // When selected...
        this.selectedPreset = element.dataset.preset as DateRangePreset;

        const dateRangeForSelectedPreset = this.getDateRangeForPreset(
          this.selectedPreset
        );
        this.selectedStartDate = dateRangeForSelectedPreset.startDate;
        this.selectedEndDate = dateRangeForSelectedPreset.endDate;

        this.updateSelectedDateInputs();
        this.validateAndToggleApplyButton();
      });
    });

    // Setup the compare checkbox to maintain its last applied state after modal close
    this.modalTarget.addEventListener('hidden.bs.modal', () => {
      this.compareCheckboxTarget.checked = this.compare;
    });

    // Setup apply button
    this.applyButtonTarget.addEventListener('click', () => {
      this.commitSelectedDateRange();
      this.compare = this.compareCheckboxTarget.checked;

      this.updateSelectedDateLabels();
      this.notifyDateRangeFilterChanged();
    });

    // Setup reset button
    this.resetButtonTarget.addEventListener('click', () => {
      this.resetDateInputValues();
      this.commitSelectedDateRange();
      this.compare = false;

      this.updateSelectedDateLabels();
      this.notifyDateRangeFilterChanged();
    });
  }

  private resetDateInputValues(): void {
    this.startDateInputTarget.value =
      this.getDefaultStartDate().format('YYYY-MM-DD');
    this.endDateInputTarget.value =
      this.getDefaultEndDate().format('YYYY-MM-DD');
  }

  private limitEndDateInputAvailableRange(): void {
    // Only allow the end date to be selectable from current to end date
    this.endDateInputTarget.setAttribute(
      'min',
      this.startDateInputTarget.value
    );
  }

  private commitSelectedDateRange(): void {
    this.selectedStartDate = moment(this.startDateInputTarget.value).startOf(
      'day'
    );
    this.selectedEndDate = moment(this.endDateInputTarget.value).endOf('day');
  }

  private updateSelectedDateInputs(): void {
    this.startDateInputTarget.value =
      this.selectedStartDate.format('YYYY-MM-DD');
    this.endDateInputTarget.value = this.selectedEndDate.format('YYYY-MM-DD');
  }

  private autoCheckPresetForDateRangeInputsValues(): void {
    const preset = this.getPresetForCurrentDateRange();

    // Current start and end date input values don't match any of the available
    // date presets.
    if (!preset) {
      this.presetDateRangeButtonTargets.forEach(
        (target) => (target.checked = false)
      );
      return;
    }

    // It matched one of the presets
    const presetButtonTarget = this.presetDateRangeButtonTargets.find(
      (target) => target.dataset.preset === preset
    );

    if (presetButtonTarget) {
      this.uncheckPreviousDateRangePreset();
      this.selectedPreset = preset;

      presetButtonTarget.checked = true;
    }
  }

  private uncheckPreviousDateRangePreset(): void {
    if (!this.selectedPreset) {
      // No preset is selected, do nothing
      return;
    }

    const currentPresetButtonTarget = this.presetDateRangeButtonTargets.find(
      (target) => target.dataset.preset === this.selectedPreset
    );

    if (currentPresetButtonTarget) {
      currentPresetButtonTarget.checked = false;
    }
  }

  private updateSelectedDateLabels(): void {
    // Update the filter button label
    this.startDateLabelTarget.innerHTML =
      this.selectedStartDate.format('Do MMM YYYY');
    this.endDateLabelTarget.innerHTML =
      this.selectedEndDate.format('Do MMM YYYY');
  }

  private getDefaultStartDate(): moment.Moment {
    return moment(this.defaultStartDateValue);
  }

  private getDefaultEndDate(): moment.Moment {
    return moment(this.defaultEndDateValue);
  }

  private areDateRangeInputsValid(): boolean {
    const startDate = moment(this.startDateInputTarget.value);
    const endDate = moment(this.endDateInputTarget.value);

    // One of the dates entered is invalid
    if (!startDate.isValid() || !endDate.isValid()) {
      return false;
    }

    return endDate.isSameOrAfter(startDate, 'date');
  }

  private validateAndToggleApplyButton(): void {
    const shouldBeEnabled = this.areDateRangeInputsValid();

    this.elementEnabledMixin.setElementEnabled(
      this.applyButtonTarget,
      shouldBeEnabled
    );
  }

  private notifyDateRangeFilterChanged(): void {
    this.dispatch('date-range-filter-changed', {
      detail: {
        selectedStartDate: this.selectedStartDate.toDate(),
        selectedEndDate: this.selectedEndDate.toDate(),
        compare: this.compare,
      },
    });
  }

  private getPresetForCurrentDateRange(): DateRangePreset | undefined {
    for (const preset of DATE_RANGE_PRESETS) {
      const rangeForPreset = this.getDateRangeForPreset(preset);
      const sameStartDate = rangeForPreset.startDate.isSame(
        this.startDateInputTarget.value,
        'date'
      );
      const sameEndDate = rangeForPreset.endDate.isSame(
        this.endDateInputTarget.value,
        'date'
      );

      if (sameStartDate && sameEndDate) {
        return preset;
      }
    }

    return undefined;
  }

  private getDateRangeForPreset(preset: DateRangePreset): {
    startDate: moment.Moment;
    endDate: moment.Moment;
  } {
    let startDate: moment.Moment;
    let endDate: moment.Moment;

    switch (preset) {
      case 'today':
        startDate = moment().startOf('day');
        endDate = moment().endOf('day');
        break;

      case 'thisWeek':
        startDate = moment().startOf('week');
        endDate = moment().endOf('week');
        break;

      case 'lastWeek':
        startDate = moment().subtract(1, 'week').startOf('day');
        endDate = moment().endOf('day');
        break;

      case 'thisMonth':
        startDate = moment().startOf('month');
        endDate = moment().endOf('month');
        break;

      case 'lastMonth':
        startDate = moment().subtract(1, 'month').startOf('month');
        endDate = moment().subtract(1, 'month').endOf('month');
        break;

      case 'last30Days':
        startDate = moment().subtract(30, 'days').startOf('day');
        endDate = moment().endOf('day');
        break;

      case 'last90Days':
        startDate = moment().subtract(90, 'days').startOf('day');
        endDate = moment().endOf('day');
        break;

      case 'thisYear':
        startDate = moment().startOf('year');
        endDate = moment().endOf('year');
        break;

      case 'lastYear':
        startDate = moment().subtract(1, 'year').startOf('year');
        endDate = moment().subtract(1, 'year').endOf('year');
        break;
    }

    return { startDate, endDate };
  }
}
