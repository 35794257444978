import CalendarController from './calendar_controller';
import moment from 'moment/moment';
import _ from 'lodash';

// Connects to data-controller="move-booking-calendar"
export default class extends CalendarController {
  static values = {
    bookingsUrl: String,
    calendarOptions: {
      defaultView: 'day',
      taskView: false,
      calendars: [],
      theme: this.calendarTheme,
      scheduleView: ['time'],
      month: {
        startDayOfWeek: 1,
      },
      week: {
        startDayOfWeek: 1,
      },
    },
    latestConfig: Object,
    schedulesUrl: String,
    newBookingTimePath: String,
  };

  static targets = ['newBookingTime', 'startTimeInput', 'endTimeInput'];

  initialize() {
    this.setCalendarOptions();
    this.initCalendar();
    this.getSchedulesRequest(this.bookingsUrlValue);
    this.setNewBookingTime();
  }

  setNewBookingTime(startTime = null, endTime = null) {
    const src =
      startTime && endTime
        ? this.newBookingTimePathValue +
          '?' +
          'start_time=' +
          startTime.toDate().toISOString() +
          '&end_time=' +
          endTime.toDate().toISOString()
        : this.newBookingTimePathValue;
    this.newBookingTimeTarget.src = src;
    if (startTime) {
      this.startTimeInputTarget.value = startTime.toDate().toISOString();
    }
    if (endTime) {
      this.endTimeInputTarget.value = endTime.toDate().toISOString();
    }
  }

  setCalendarOptions() {
    this.calendarOptionsValue = {
      ...this.calendarOptionsValue,
      ...this.calendarOptions,
    };
  }

  getSchedulesRequestsResponse(res) {
    this.calendar.clear();
    if (res['bookings']) this.loadBookingRequests(res['bookings']);
    if (res['selected_instance'])
      this.loadSelectedInstance(res['selected_instance']);
    if (res['closed_hours']) this.loadClosedHours(res['closed_hours']);
    if (res['date']) this.setCalendarDate(res['date']);
  }

  setCalendarDate(date) {
    this.calendar.setDate(moment(date).toDate());
  }

  eventHasConflict(e, calendarId) {
    const conflicts = _.filter(
      this.getEventsForCalendarId(calendarId),
      function (scheduledEvent) {
        return (
          (scheduledEvent.start >= e.start && scheduledEvent.end <= e.end) ||
          (e.start > scheduledEvent.start && e.start < scheduledEvent.end) ||
          (e.end > scheduledEvent.start && e.end < scheduledEvent.end)
        );
      }
    );

    return !_.isEmpty(conflicts);
  }

  beforeCreateScheduleEvent(e) {
    e.guide.clearGuideElement();
  }

  beforeUpdateScheduleEvent(e) {
    if (this.eventHasConflict(e, 'closedHours')) return;
    if (this.eventHasConflict(e, 'other')) return;

    const { schedule, changes } = e;
    // only update if both end and start time change, else the duration will have changed
    if (changes.end && changes.start) {
      this.calendar.updateSchedule(schedule.id, schedule.calendarId, changes);
      this.setNewBookingTime(changes.start, changes.end);
    }
  }

  loadClosedHours(closed_hours) {
    const closedSchedule = this.closedHoursToCalendarSchedule(
      closed_hours,
      'closedHours'
    );

    this.calendar.createSchedules(closedSchedule);
  }

  loadBookingRequests(bookings) {
    const otherBookingsSchedule = this.bookingsToCalendarSchedule({
      instances: bookings,
      calendarId: 'other',
      title: 'Unavailable',
      readOnly: true,
    });

    this.calendar.createSchedules([...otherBookingsSchedule]);
  }

  loadSelectedInstance(bookings) {
    const currentBookingSchedule = this.bookingsToCalendarSchedule({
      instances: bookings,
      calendarId: 'editing',
      title: 'Drag to move',
    });

    this.calendar.createSchedules([...currentBookingSchedule]);
  }

  closedHoursToCalendarSchedule(closedHours, calendarId) {
    return closedHours.map((data) => {
      return {
        calendarId: calendarId,
        category: 'time',
        start: this.stripTimeZone(data.start_time),
        end: this.stripTimeZone(data.end_time),
        isReadOnly: true,
        customStyle: 'cursor:default',
      };
    });
  }

  bookingsToCalendarSchedule({
    instances = instances,
    calendarId,
    title,
    readOnly = null,
  }) {
    return instances.map((data) => {
      return {
        calendarId: calendarId,
        title: title,
        category: 'time',
        start: this.stripTimeZone(data.start_time),
        end: this.stripTimeZone(data.end_time),
        isReadOnly: readOnly,
      };
    });
  }

  calendars = [
    {
      id: 'closedHours',
      name: 'Closed Hours',
      bgColor: '#c2c6cd80',
      borderColor: '#c2c6cd80',
    },
    {
      id: 'other',
      name: 'Other bookings',
      bgColor: '#e1e1e1',
      dragBgColor: '#e1e1e1',
      borderColor: '#5b5b5b',
    },
    {
      id: 'editing',
      name: 'Current booking',
      bgColor: '#cefddc',
      dragBgColor: '#bbf4cb',
      borderColor: '#38ff6c',
    },
  ];

  calendarOptions = {
    calendars: this.calendars,
  };
}
